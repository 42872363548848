/* .vtab {
  height: "100%";
  width: "33%";
  float: left;
}

.vtabActive {
  width: "100%";
  border-left-color: "#4D8EBC";
  border-left-style: solid;
  border-left-width: 3;
  border-right-color: "#4D8EBC";
  border-right-style: none;
  border-right-width: 0;
}

.vtabPassive {
  width: "100%";
  border-left-color: "#4D8EBC";
  border-left-style: solid;
  border-left-width: 1;
  border-right-color: "#4D8EBC";
  border-right-style: none;
  border-right-width: 1;
}
.vtabContainer {
  height: "100%";
  width: "66%";
  float: left;
} */

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
