body {
  font-family: Calibri, "Trebuchet MS", sans-serif, "Gill Sans", "Gill Sans MT";
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.boxTextAlignCenter {
  text-align: center;
}

.boxTextAlignCenterInner {
  width: 100px;
  height: 100px;
  display: inline-block;
}

.boxCenter {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.boxCircleInner50 {
  margin-top: 25px;
  height: 50px;
}

.boxRadius {
  border-radius: 10%;
}

.boxCircle {
  border-radius: 50%;
  text-align: center;
}

.boxCircle25 {
  width: 25px;
  height: 25px;
  border: 2px solid black;
}

.boxCircle50 {
  width: 50px;
  height: 50px;
  margin: 10;
  border: 7px solid gray;
}

.boxCircle75 {
  width: 75px;
  height: 75px;
  border: 15px solid gray;
}

.boxCircle100 {
  width: 100px;
  height: 100px;
  border: 15px solid gray;
}

.boxCircle125 {
  width: 125px;
  height: 125px;
  border: 15px solid gray;
}

.boxCircleLime {
  background: lime;
}

.boxCircleSilver {
  background: silver;
}

.boxBorderBeige {
  border: 1px solid beige;
}

.boxBorderGray {
  border: 1px solid gray;
}

.boxBorderRed {
  border: 1px solid red;
}

.boxBorderBlack {
  border: 1px solid black;
}

.boxBlack {
  background-color: black;
  color: white;
}

.boxWhite {
  background-color: white;
  color: black;
}

.boxOrange {
  background-color: orange;
  color: black;
}

.boxGreeny {
  background-color: #a9d08e;
  color: black;
}

.boxDarkRed {
  background-color: rgba(196, 13, 13, 0.76);
  color: white;
}

.boxBorderTransparent {
  border: 1px solid transparent;
}

.boxRight {
  float: right;
}

.boxLeft {
  float: left;
}

.boxWidth2_5 {
  width: 2.5%;
}

.boxWidth7_5 {
  width: 7.5%;
}

.boxWidth32_5 {
  width: 32.5%;
}

.boxWidth10 {
  width: 10%;
}

.boxWidth15 {
  width: 15%;
}

.boxWidth20 {
  width: 20%;
}

.boxWidth25 {
  width: 25%;
}

.boxWidth33 {
  width: 33%;
}

.boxWidth50 {
  width: 50%;
}

.boxWidth80 {
  width: 80%;
}

.boxWidth100 {
  width: 100%;
  padding: 0;
  margin: 0;
}

.boxHeight1 {
  height: 1%;
}

.boxHeight10 {
  height: 10%;
}

.boxHeight20 {
  height: 20%;
}

.boxHeight70 {
  height: 70%;
}

.boxHeight80 {
  height: 80%;
}

.boxHeight90 {
  height: 90%;
}

.boxHeight100 {
  height: 100%;
}

.akisButton {
  -moz-box-shadow: 13px 14px 0px 0px #899599;
  -webkit-box-shadow: 13px 14px 0px 0px #899599;
  box-shadow: 13px 14px 0px 0px #899599;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #ededed),
    color-stop(1, #bab1ba)
  );
  background: -moz-linear-gradient(top, #ededed 5%, #bab1ba 100%);
  background: -webkit-linear-gradient(top, #ededed 5%, #bab1ba 100%);
  background: -o-linear-gradient(top, #ededed 5%, #bab1ba 100%);
  background: -ms-linear-gradient(top, #ededed 5%, #bab1ba 100%);
  background: linear-gradient(to bottom, #ededed 5%, #bab1ba 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#bab1ba', GradientType=0);
  background-color: #ededed;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  border-radius: 14px;
  border: 2px solid #d6bcd6;
  display: inline-block;
  cursor: pointer;
  color: #084f61;
  font-size: 21px;
  padding: 25px 75px;
  text-decoration: none;
  text-shadow: 15px 15px 4px #e1e2ed;
  width: 50%;
}

.akisButton:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #bab1ba),
    color-stop(1, #ededed)
  );
  background: -moz-linear-gradient(top, #bab1ba 5%, #ededed 100%);
  background: -webkit-linear-gradient(top, #bab1ba 5%, #ededed 100%);
  background: -o-linear-gradient(top, #bab1ba 5%, #ededed 100%);
  background: -ms-linear-gradient(top, #bab1ba 5%, #ededed 100%);
  background: linear-gradient(to bottom, #bab1ba 5%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bab1ba', endColorstr='#ededed', GradientType=0);
  background-color: #bab1ba;
}

.akisButton:active {
  position: relative;
  top: 1px;
}

#divIsProgramiBaslik {
  font-size: small;
  border-collapse: collapse;
  width: 100%;
}

#divIsProgramiBaslik .baslik {
  background-color: #ddd;
  text-align: left;
  font-weight: bold;
  padding-left: 5px;
}

#divIsProgramiBaslik input {
  padding-left: 5;
  border: 0px;
  font-size: 100%;
  text-align: left;
}

#divIsProgramiBaslik textarea {
  border: 0px;
  font-size: 100%;
  text-align: center;
}

#divIsProgramiBaslik td,
#divIsProgramiBaslik th {
  border: 1px solid black;
  margin: 0;
  padding: 0px;
  text-align: center;
}

#divIsProgramiBaslik tr:nth-child(even) {
  margin: 0;
}

#divIsProgramiBaslik tr:hover {
  margin: 0;
}

#divIsProgramiBaslik th {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
  background-color: white;
  color: #6163d3;
}

/* ---------------- */

#divBolumMasrafKalemTablo {
  font-size: small;
  border-collapse: collapse;
  width: 100%;
}

#divBolumMasrafKalemTablo input {
  border: 0px;
  font-size: 100%;
  text-align: center;
}

#divBolumMasrafKalemTablo td,
#divBolumMasrafKalemTablo th {
  border: 1px solid black;
  margin: 0;
  padding: 0px;
  text-align: left;
  font-size: 52;
}

#divBolumMasrafKalemTablo tr:nth-child(even) {
  margin: 0;
}

#divBolumMasrafKalemTablo tr:hover {
  margin: 0;
}

#divBolumMasrafKalemTablo th {
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
  background-color: #c6e0b4;
  font-size: 51;
}

/* ---------------- */
#sahneDokumuTable {
  font-size: small;
  border-collapse: collapse;
  width: 100%;
}

#sahneDokumuTable input {
  border: 0px;
  font-size: 100%;
  text-align: center;
}

#sahneDokumuTable textarea {
  border: 0px;
  font-size: 100%;
  text-align: center;
}

#sahneDokumuTable td,
#sahneDokumuTable th {
  border: 1px solid #ddd;
  margin: 0;
  padding: 0px;
  text-align: center;
}

#sahneDokumuTable tr:nth-child(even) {
  background-color: #f2f2f2;
  margin: 0;
}

#sahneDokumuTable tr:hover {
  background-color: #ddd;
  margin: 0;
}

#sahneDokumuTable th {
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
  background-color: silver;
  color: black;
}

#divSahneDokumuBaslik div {
  float: left;
  padding: 0%;
  margin: 0%;
  background-color: darkgray;
  color: white;
  font-size: 0.8em;
  border-width: 1px;
  border-style: solid;
  text-align: center;
}

.divSahneDokumuRow {
  display: table-row;
}

.divSahneDokumuCell {
  padding: 0%;
  margin: 0%;
  height: 100%;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  text-align: center;
  display: table-cell;
}

.divSahneDokumuCell input {
  border: 0px;
  font-size: 80%;
  text-align: center;
}

.divSahneDokumuCell textarea {
  border: 0px;
  font-size: 100%;
  text-align: center;
}

.rTable {
  display: table;
}

.rTableRow {
  display: table-row;
}

.rTableHeading {
  display: table-header-group;
}

.rTableBody {
  display: table-row-group;
}

.rTableFoot {
  display: table-footer-group;
}

.rTableCell,
.rTableHead {
  display: table-cell;
}

.yoksaEkle {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #c62d1f),
    color-stop(1, #f24437)
  );
  background: -moz-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: -webkit-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: -o-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: -ms-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: linear-gradient(to bottom, #c62d1f 5%, #f24437 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c62d1f', endColorstr='#f24437', GradientType=0);
  background-color: #c62d1f;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 13px;
  padding: 4px 10px;
  text-decoration: none;
}

.yoksaEkle:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #f24437),
    color-stop(1, #c62d1f)
  );
  background: -moz-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: -webkit-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: -o-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: -ms-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: linear-gradient(to bottom, #f24437 5%, #c62d1f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f24437', endColorstr='#c62d1f', GradientType=0);
  background-color: #f24437;
}

.yoksaEkle:active {
  position: relative;
  top: 1px;
}

.sahneDokumuKaydet {
  -moz-box-shadow: 0px 10px 14px -7px #3e7327;
  -webkit-box-shadow: 0px 10px 14px -7px #3e7327;
  box-shadow: 0px 10px 14px -7px #3e7327;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #77b55a),
    color-stop(1, #72b352)
  );
  background: -moz-linear-gradient(top, #77b55a 5%, #72b352 100%);
  background: -webkit-linear-gradient(top, #77b55a 5%, #72b352 100%);
  background: -o-linear-gradient(top, #77b55a 5%, #72b352 100%);
  background: -ms-linear-gradient(top, #77b55a 5%, #72b352 100%);
  background: linear-gradient(to bottom, #77b55a 5%, #72b352 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#77b55a', endColorstr='#72b352', GradientType=0);
  background-color: #77b55a;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #4b8f29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  padding: 6px 12px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #5b8a3c;
}

.sahneDokumuKaydet:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #72b352),
    color-stop(1, #77b55a)
  );
  background: -moz-linear-gradient(top, #72b352 5%, #77b55a 100%);
  background: -webkit-linear-gradient(top, #72b352 5%, #77b55a 100%);
  background: -o-linear-gradient(top, #72b352 5%, #77b55a 100%);
  background: -ms-linear-gradient(top, #72b352 5%, #77b55a 100%);
  background: linear-gradient(to bottom, #72b352 5%, #77b55a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#72b352', endColorstr='#77b55a', GradientType=0);
  background-color: #72b352;
}

.sahneDokumuKaydet:active {
  position: relative;
  top: 1px;
}

.sahneDokumuSil {
  -moz-box-shadow: inset 0px 39px 0px -24px #e67a73;
  -webkit-box-shadow: inset 0px 39px 0px -24px #e67a73;
  box-shadow: inset 0px 39px 0px -24px #e67a73;
  background-color: #e4685d;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #b23e35;
}

.sahneDokumuSil:hover {
  background-color: #eb675e;
}

.sahneDokumuSil:active {
  position: relative;
  top: 1px;
}

.sahneDokumuBolumSec {
  -moz-box-shadow: inset 0px 1px 0px 0px #fff6af;
  -webkit-box-shadow: inset 0px 1px 0px 0px #fff6af;
  box-shadow: inset 0px 1px 0px 0px #fff6af;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #ffec64),
    color-stop(1, #ffab23)
  );
  background: -moz-linear-gradient(top, #ffec64 5%, #ffab23 100%);
  background: -webkit-linear-gradient(top, #ffec64 5%, #ffab23 100%);
  background: -o-linear-gradient(top, #ffec64 5%, #ffab23 100%);
  background: -ms-linear-gradient(top, #ffec64 5%, #ffab23 100%);
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffec64', endColorstr='#ffab23', GradientType=0);
  background-color: #ffec64;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
}

/* Select'teki drop down'u kaldırmak için yaptım ama olmadı */
.Select-arrow-zone {
  display: none;
}

.css-1wy0on6 {
  display: none;
}

.Select-menu-outer {
  display: none;
}

/* Select'teki drop down'u kaldırmak için yaptım ama olmadı */
.sahneDokumuBolumSec:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #ffab23),
    color-stop(1, #ffec64)
  );
  background: -moz-linear-gradient(top, #ffab23 5%, #ffec64 100%);
  background: -webkit-linear-gradient(top, #ffab23 5%, #ffec64 100%);
  background: -o-linear-gradient(top, #ffab23 5%, #ffec64 100%);
  background: -ms-linear-gradient(top, #ffab23 5%, #ffec64 100%);
  background: linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffab23', endColorstr='#ffec64', GradientType=0);
  background-color: #ffab23;
}

.sahneDokumuBolumSec:active {
  position: relative;
  top: 1px;
}

/*************DEVEXTREME************/
#simple-treeview,
#product-details {
  display: inline-block;
}

#product-details {
  vertical-align: top;
  width: 400px;
  height: 420px;
  margin-left: 20px;
}

#product-details > img {
  border: none;
  height: 300px;
  width: 400px;
}

#product-details > .name {
  text-align: center;
  font-size: 20px;
}

#product-details > .price {
  text-align: center;
  font-size: 24px;
}

.dark #product-details > div {
  color: #f0f0f0;
}

.hidden {
  visibility: hidden;
}
/***************DEVEXTREME*******************************/
