
.radial-nav {
  position: relative;
  display: inline-block;
  width:170px;
  height:170px;
  -webkit-transform-origin: center center;
  .menu{
      border: none !important;
      position:relative;
      width:170px;
      height:170px;
      z-index:100;
      transition: all 0.5s;
      display: table-cell;
      vertical-align: middle;
      &:active {
        -webkit-transform: rotate(-15deg);
      }
    }
  li:not(.menu) {
    position: absolute;
    left: 0;
    width: 170px;
    height: 170px;
    border-radius:70px;
    border: 1px solid transparent;    
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s;
    z-index:1;
    &.selected {
        -webkit-transform: rotate(0deg) translateX(120px) rotate(-0deg);
        border: 1px solid #5151F1;
        a {opacity:1;}
     }
    
    &:before {
      content:"";
      display:inline-block;
      vertical-align:middle;
      height:100%;
    }
    a{
      display:inline-block;
      vertical-align:middle;
      opacity:0;
      -webkit-transition: all 0.5s;
    }
  }

    li:nth-child(1) {
      -webkit-transform: translateY(-120px);
    }
    li:nth-child(2) {
      
        -webkit-transform: translateY(-50px)translateX(110px);
      }
    li:nth-child(3) {
        -webkit-transform: translateY(-50px)translateX(-110px);
    }
    li:nth-child(4) {
        -webkit-transform: translateY(120px);
    }
    li:nth-child(5) {
        -webkit-transform: translateY(70px)translateX(-110px);
    }
    li:nth-child(6) {
        -webkit-transform: translateY(-30px) translateX(0px);
    }
    li:nth-child(7) {
        -webkit-transform: translateY(45px) translateX(30px);
    }
     li {
       a{
         opacity: 1;
       }
     }
    .icon-menu {
      border-color: #5151F1;
     
    }
    .menu{
      &:active {
        -webkit-transform: rotate(15deg) !important;
      }
    }
  
 }

.icon-menu {
  display: inline-block;
  border-top: 3px solid #141444;
  border-bottom: 3px solid #141444;
  width: 76px;
  height:76px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  &:before {
    content: "";
    display: inline-block;
    border-bottom: 3px solid #141444;
    width:100%;
    -webkit-transition: all 0.3s;
  }
  &:hover {
    border-color: #00003A;
    &:before {
      border-color:#00003A;
    }
  }
}
